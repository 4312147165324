<template>
  <Card
    class="c-mileStageCard"
    :class="{
      'c-card--beige03Bg': highlight,
      'c-mileStageCard--highlight': highlight
    }">
    <div>
      <div class="c-mileStageCard__header">
        <div class="c-mileStageCard__title">
          <span>{{ mileStageId }}</span>
          {{ mileStageName }}
        </div>
        <div class="c-mileStageCard__flag">
          <img :src="flagUrl" alt="" />
        </div>
      </div>
      <div class="c-mileStageCard__content">
        {{ mileStageDescription }}
      </div>
    </div>
  </Card>
</template>

<script>
import { STAGE_LIST } from '@/constants/MileStage';

export default {
  props: {
    // 1 - 24
    stage: {
      type: Number,
      required: true,
      validator: (v) => v >= 1 && v <= STAGE_LIST.length
    }
  },

  computed: {
    stageDefinition() {
      return STAGE_LIST[this.stage - 1];
    },

    highlight() {
      return this.stageDefinition.isRelatedPlace;
    },

    mileStageId() {
      return String(this.stage).padStart(2, '0');
    },

    mileStageName() {
      return this.stageDefinition.nameJa;
    },

    mileStageDescription() {
      return this.stageDefinition.description;
    },

    flagUrl() {
      const key = this.stageDefinition.nameEn.replace(' ', '').toLowerCase();
      return `/assets/img/flag/${key}.svg`;
    }
  }
};
</script>
