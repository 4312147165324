var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    {
      staticClass: "c-mileStageCard",
      class: {
        "c-card--beige03Bg": _vm.highlight,
        "c-mileStageCard--highlight": _vm.highlight,
      },
    },
    [
      _c("div", [
        _c("div", { staticClass: "c-mileStageCard__header" }, [
          _c("div", { staticClass: "c-mileStageCard__title" }, [
            _c("span", [_vm._v(_vm._s(_vm.mileStageId))]),
            _vm._v(" " + _vm._s(_vm.mileStageName) + " "),
          ]),
          _c("div", { staticClass: "c-mileStageCard__flag" }, [
            _c("img", { attrs: { src: _vm.flagUrl, alt: "" } }),
          ]),
        ]),
        _c("div", { staticClass: "c-mileStageCard__content" }, [
          _vm._v(" " + _vm._s(_vm.mileStageDescription) + " "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }